// let apiUrl = process.env.NODE_ENV === 'production' ? 'http://192.168.43.152:3000/api/' : 'http://192.168.43.152:3000/api/';
let apiUrl = process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:3001/api/';

{
	var parser = document.createElement('a');
	parser.href = apiUrl;
	window.apiOrigin = parser.origin;
}


export default {
	methods: {
		async fetch(path, method, data) {
			let headers = {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			};

			let accountType = '';
			if (path.indexOf("center") == 0) accountType = 'center';
			if (path.indexOf("radiologist") == 0) accountType = 'radiologist';
			if (path.indexOf("cardiologist") == 0) accountType = 'cardiologist';
			if (path.indexOf("pulmonologist") == 0) accountType = 'pulmonologist';
			if (path.indexOf("neurologist") == 0) accountType = 'neurologist';
			if (path.indexOf("admin") == 0) accountType = 'admin';
			if (path.indexOf("agent") == 0) accountType = 'agent';

			if (accountType) {
				let token = null;
				
				let user = localStorage.getItem(accountType + '_access_token');
				if(user){
					user = JSON.parse(user);
					user = {
						email: user.email,
						password: user.password
					};
					token = JSON.stringify(user);
				}

				token = token ? 'Bearer ' + token : null
				if (token) headers['Authorization'] = token;
			}


			let content = {
				method: method,
				headers,
			}
			if (method != "GET") content['body'] = JSON.stringify(data);

			let fullPath = (path.startsWith('http://') || path.startsWith('https://')) ? path : apiUrl + path;

			try {
				const res = await fetch(fullPath, content);
				if (res.status == 403 || res.status == 401) {
					localStorage.removeItem(accountType + 'access_token')
					return this.$router.push({
						path: '/' + accountType + '-login',
						query: {
							return_path: this.$router.currentRoute._value.fullPath
						}
					});
				}
				if (res.headers.get('x-auth-token')) {
					localStorage.setItem('access_token', res.headers.get('x-auth-token'));
				}
				return await res.json();
			} catch (e) {
				return new Promise((resolve, reject) => {
					reject(e);
				});
			}
		},
		async post(path, data) {
			return await this.fetch(path, 'POST', data);
		},
		async get(path) {
			return await this.fetch(path, 'GET');
		},
		async delete(path, data) {
			return await this.fetch(path, 'DELETE', data);
		},
		async put(path, data) {
			return await this.fetch(path, 'PUT', data);
		},
		async patch(path, data) {
			return await this.fetch(path, 'PATCH', data);
		},
		upload(path, data, cb, progress) {
			let url = apiUrl + path;
			function displayProgress(event) {
				var t = event.total;
				var u = event.loaded;
				var s = u / t;
				if (progress) progress(s);
			}
			if (window.XMLHttpRequestUpload && window.FormData) {
				var fd = new FormData();
				for (var i in data) {
					fd.append(i, data[i]);
				}
				if (progress) progress(0.01);
				var ajax = new XMLHttpRequest();
				ajax.upload.addEventListener("progress", displayProgress);
				ajax.addEventListener("progress", displayProgress);
				ajax.addEventListener("load", function () {
					if (ajax.readyState == 4 && ajax.status == 200) {
						let o = ajax.responseText;
						try {
							o = JSON.parse(o);
						} catch (err) { err; }
						cb(null, o);
					} else {
						cb(ajax);
					}
				});
				ajax.addEventListener("error", function (err) {
					cb(err);
				});
				ajax.open("POST", url);

				let accountType = '';
				if (path.indexOf("center") == 0) accountType = 'center';
				if (path.indexOf("radiologist") == 0) accountType = 'radiologist';
				if (path.indexOf("cardiologist") == 0) accountType = 'cardiologist';
				if (path.indexOf("pulmonologist") == 0) accountType = 'pulmonologist';
				if (path.indexOf("neurologist") == 0) accountType = 'neurologist';
				if (path.indexOf("admin") == 0) accountType = 'admin';

				if (accountType) {
					let token = null;
				
					let user = localStorage.getItem(accountType + '_access_token');
					if(user){
						user = JSON.parse(user);
						user = {
							email: user.email,
							password: user.password
						};
						token = JSON.stringify(user);
					}

					token = token ? 'Bearer ' + token : null
					if (token) ajax.setRequestHeader("Authorization", token);
				}

				ajax.send(fd);
			}
		},
	},
	computed: {
		apiOrigin() {
			var parser = document.createElement('a');
			parser.href = apiUrl;
			return parser.origin;
		}
	}
}
