<template>
	<div>
		<Toast />
		<div class="sidebar">
			<div class="logo">
				<img class="small" src="../../assets/teleradio-sm.png" alt="">
				<img class="full" src="../../assets/teleradio.png" alt="">
			</div>
			<div class="snav-container">
				<div class="snav">
					<div class="title">Menu</div>
					<ul>
						<li>
							<router-link to="/center/dashboard">
								<i class="fa fa-dashboard"></i>
								<span>Dashboard</span>
							</router-link>
						</li>
						<li>
							<router-link to="/center/xray-report">
								<i class="fa fa-xing-square"></i>
								<span class="text">Radiology Reporting</span>
							</router-link>
						</li>
						<li>
							<router-link to="/center/ecg-report">
								<i class="fa fa-heartbeat"></i>
								<span class="text">Cardiology Reporting</span>
							</router-link>
						</li>
						<li>
							<router-link to="/center/pft-report">
								<i class="fa fa-solid fa-lungs"></i>
								<span class="text">Pulmonology Reporting</span>
							</router-link>
						</li>
						<li>
							<router-link to="/center/eeg-report">
								<i class="fa fa-solid fa-brain"></i>
								<span class="text">Neurology Reporting</span>
							</router-link>
						</li>
						<li :class="{ open: openDropdown == 'accounts' }">
							<a @click="openDropdown = openDropdown == 'accounts' ? '' : 'accounts'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-briefcase"></i>
								<span>Accounts</span>
							</a>
							<ul>
								<li>
									<router-link to="/center/payment">
										<i class="fa fa-plus"></i>
										<span class="text">Payment</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/billing-xray">
										<i class="fa fa-credit-card"></i>
										<span class="text">Xray Billing</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/billing-ecg">
										<i class="fa fa-credit-card-alt"></i>
										<span class="text">ECG Billing</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/billing-pft">
										<i class="fa fa-credit-card"></i>
										<span class="text">PFT Billing</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/billing-eeg">
										<i class="fa fa-solid fa-credit-card"></i>
										<span class="text">EEG Billing</span>
									</router-link>
								</li>
							</ul>
						</li>
						<li :class="{ open: openDropdown == 'settings' }">
							<a @click="openDropdown = openDropdown == 'settings' ? '' : 'settings'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-gear"></i>
								<span>Settings</span>
							</a>
							<ul>
								<li>
									<router-link to="/center/general-settings">
										<i class="fa fa-solid fa-screwdriver-wrench"></i>
										<span class="text">General Settings</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/rate-chart">
										<i class="fa fa-money"></i>
										<span class="text">Rate Chart</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/prefer-radiologist">
										<i class="fa fa-user-md"></i>
										<span class="text">Prefer Radiologist</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/prefer-cardiologist">
										<i class="fa fa-user-md"></i>
										<span class="text">Prefer Cardiologist</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/prefer-pulmonologist">
										<i class="fa fa-user-md"></i>
										<span class="text">Prefer Pulmonologist</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/prefer-neurologist">
										<i class="fa fa-user-md"></i>
										<span class="text">Prefer Neurologist</span>
									</router-link>
								</li>
								<li>
									<router-link to="/center/contact-admin">
										<i class="fa fa-phone"></i>
										<span class="text">Contact Admin</span>
									</router-link>
								</li>
							</ul>
						</li>
						<li>
							<router-link @click.prevent="logOut" to="/">
								<i class="fa fa-lock"></i>
								<span class="text">Logout</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="top-container">
			<header>
				<div class="rnav">
					<div title="Support">
						<span class="text-success" @click="support = {}; ticketRaised = false; addModal.show();">
							<i class="fa-solid fa-headset"></i>
						</span>
					</div>
					<div @click="logOut" title="Logout">
						<i class="fa fa-lock"></i>
					</div>
					<div @click="notiopen = !notiopen; notiFocus(); loadNotifications();">
						<i :style="{ 'color': center?.has_notice == 'y' ? 'red' : 'inherit' }" class="fa fa-bell"></i>
						<div @click.stop="" ref="noti" @blur="notiopen = false" tabindex="-1" v-show="notiopen"
							class="notifications">
							<div class="noti" v-for="i in notifications" :key="i">
								<div class="date">Date: {{ format(i.created_at, "DD/MM/YY hh:mma") }}</div>
								{{ i.message }}
							</div>
						</div>
					</div>
					<div title="Speed check">
						<a href="https://fast.com" target="_balnk">
							<i class="fa fa-signal"></i>
						</a>
					</div>
					<!-- <div title="WhatsApp">
						<a class="text-success" href="https://wa.me/message/CCIEK2E6ALTCI1" target="_balnk">
							<i class="fa fa-whatsapp"></i>
						</a>
					</div> -->
					
					<div v-if="helplineNumber">
						<div style="font-size: 18px;">
							<!-- Reporting Helpline Number:  -->
							<div style="background: rgb(255, 205, 9); color: #000; font-weight: bold; font-size: 20px; display: inline-block; padding: 0px 5px; border-radius: 6px;">{{helplineNumber}}</div>
						</div>
					</div>
				</div>
				<div class="tnav">
					<ul class="clearfix">
						<li>
							<router-link to="./">Dashboard</router-link>
						</li>
					</ul>
				</div>
			</header>
			<div class="content">
				<router-view></router-view>
			</div>
		</div>






		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title clearfix" style="width: 100%;">
								<div class="pull-left">
									<i class="fa-solid fa-headset"></i> Support
								</div>

								<div class="pull-right" style="margin-right: 20px;">
									<router-link to="/center/manage-tickets" @click="addModal.hide()" style="text-decoration: none; font-size: 18px;">Manage Tickets</router-link>
								</div>
								

							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="clearfix">
								<div class="pull-right" style="margin-left: 100px; margin-bottom: 10px;">
									<a class="text-success" href="https://wa.me/message/CCIEK2E6ALTCI1" target="_balnk" style="font-size: 18px; text-decoration: none;">
										<i class="fa fa-whatsapp" style="font-size: 24px;"></i> WhatsApp Support
									</a>
								</div>
							</div>

							<div v-if="!ticketRaised">
								<div class="form-group" style="font-size: 20px; margin-bottom: 10px;">
									<label>
										<input type="radio" name="support_reason" value="Report Delay" v-model="support.support_reason">
										Report Delay
									</label>
									&nbsp;&nbsp;
									<label>
										<input type="radio" name="support_reason" value="Review Request" v-model="support.support_reason">
										Review Request
									</label>
									&nbsp;&nbsp;
									<label>
										<input type="radio" name="support_reason" value="Book Call Back" v-model="support.support_reason">
										Book Call Back
									</label>
								</div>
								<div v-if="support.support_reason && support.support_reason != 'Book Call Back'" class="form-group ui-float-label">
									<select class="form-control" v-model="support.report_type">
										<option value="X-Ray">X-Ray</option>
										<option value="ECG">ECG</option>
										<option value="EEG">EEG</option>
										<option value="PFT">PFT</option>
									</select>
									<label>Report Type</label>
								</div>
								<div v-if="support.support_reason && support.support_reason != 'Book Call Back'" class="form-group ui-float-label">
									<input type="text" class="form-control" v-model="support.report_id" 
										placeholder=" " />
									<label>Report ID</label>
								</div>
								<div v-if="support.support_reason && support.support_reason == 'Book Call Back'" class="form-group ui-float-label">
									<input type="tel" class="form-control" v-model="support.center_mobile_no" placeholder=" " />
									<label>Contact Number (Number to receive call upon)</label>
								</div>
								
								<div v-if="support.support_reason" class="form-group ui-float-label">
									<textarea type="text" class="form-control" v-model="support.reason_description" 
										 placeholder=" " ></textarea>
									<label>Comment (Optional)</label>
								</div>
							</div>
							<div v-else style="font-size: 17px;">
								<div v-if="support.support_reason == 'Report Delay'">
									your report is assigned to {{doctor}} <br>
									report staus is {{status}} <br>
									uploaded since {{since}}. <br>
									we will get back to you shortly <br>
								</div>
								<div v-if="support.support_reason == 'Review Request'">
									One of our staff will check and get back to you shortly.
								</div>
								<div v-if="support.support_reason == 'Book Call Back'">
									One of our support staff will call you shortly.
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" v-if="!ticketRaised" class="btn btn-primary" :disabled="ticketRaising">{{ ticketRaising ? "Raising..." : "Raise Ticket"}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>







	</div>
</template>
<script>
import { Modal } from 'bootstrap'
import Toast from 'primevue/toast';
export default {
	components: {
		Toast
	},
	data() {
		return {
			support: {},
			ticketRaised: false,
			ticketRaising: false,
			doctor: '',
			since: '',
			status: '',

			openDropdown: '',
			activeLink: '',
			notiopen: false,
			notifications: [],
			center: null,
			helplineNumber: '',

			addModal: null,
		}
	},
	beforeRouteEnter(req, res, next) {
		if (!window.localStorage.center_access_token) return next("/center-login");
		next();
	},
	async mounted() {
		this.addModal = new Modal(this.$refs.addModal, { backdrop: 'static', keyboard: false });

		window.soc = io(apiOrigin, { transports: ["websocket"] });
		let at = JSON.parse(localStorage.center_access_token);
		soc.emit('login', {
			type: 'center',
			id: at.id
		})

		this.center = await this.get("center/load-center");
		this.helplineNumber = (await this.get('center/load-helpline-number')).helpline_number;


		if(!window.MicRecorder){

			fetch("/fw/mic-recorder-to-mp3.min.js").then(res=>res.text()).then(res=>{
				let e =  document.createElement('script');
				e.innerHTML = res;
				document.head.appendChild(e);


				setTimeout(()=>{
					window.micRecorder = new window.MicRecorder({
						bitRate: 64,
					});
				},0)

			})

		}
	},
	unmounted(){
		soc.close();
	},
	methods: {
		logOut() {
			delete window.localStorage.center_access_token;
			this.$router.push("/center-login");
		},
		notiFocus() {
			this.$nextTick(() => {
				this.$refs.noti.focus();
			})
		},
		async loadNotifications() {
			if (!this.notiopen) return;
			this.notifications = await this.get('center/load-notifications');
			
			if (this.center?.has_notice) {
				await this.post("center/reset-notice")
				this.center = await this.get("center/load-center");
			}
		},

		async putRecord(){
			if(this.ticketRaising) return;
			this.ticketRaising = true;
			if(!this.support.support_reason){
				return this.$toast.add({ severity: 'info', summary: 'Support Reason Required', detail: 'Please select support reason', life: 3000 });
			}
			// if(!this.support.reason_description){
			// 	return this.$toast.add({ severity: 'info', summary: 'Comment Required', detail: 'Please type some comment.', life: 3000 });
			// }
			if(this.support.support_reason != 'Book Call Back' && !this.support.report_id){
				return this.$toast.add({ severity: 'info', summary: 'Report ID Required', detail: 'Please enter report id.', life: 3000 });
			}

			let report_id = 0;
			if(this.support.support_reason != 'Book Call Back'){
				report_id = this.support.report_id;
			}

			let center = JSON.parse(localStorage.center_access_token);

			let fd = {
				module: 'TR',
				support_reason: this.support.support_reason,
				reason_description: this.support.reason_description ? this.support.reason_description : "",
				center_id: center.id,
				center_name: center.name,
				owner_name: center.contact_person,
				center_mobile_no: center.phone,
				report_type: this.support.report_type ? this.support.report_type : 'null',
				report_id
			};

			if(this.support.support_reason == 'Book Call Back' && this.support.center_mobile_no){
				fd.center_mobile_no = this.support.center_mobile_no;
			}
			

			if(report_id){
				let params = {
					filters: {
						id: {
							matchMode: '=',
							value: report_id,
						}
					},
					first: 0,
					rows: 1,
					sortField: "id",
					sortOrder: -1,
				}
				let path;
				if(this.support.report_type == 'X-Ray'){
					params.joins = [
						{
							"fTable": "radiologists",
							"fKey": "assign_doc_id",
							"fField": [["name", "assign_doc_name"]]
						},
					]
					path = "center/load-xray-reports";
				}else if(this.support.report_type == 'ECG'){
					params.joins = [
						{
							"fTable": "cardiologists",
							"fKey": "assign_doc_id",
							"fField": [["name", "assign_doc_name"]]
						}
					]
					path = "center/load-ecg-reports";
				}else if(this.support.report_type == 'EEG'){
					params.joins = [
						{
							"fTable": "neurologists",
							"fKey": "assign_doc_id",
							"fField": [["name", "assign_doc_name"]]
						}
					]
					path = "center/load-eeg-reports";
				}else if(this.support.report_type == 'PFT'){
					params.joins = [
						{
							"fTable": "pulmonologists",
							"fKey": "assign_doc_id",
							"fField": [["name", "assign_doc_name"]]
						}
					]
					path = "center/load-pft-reports";
				}
				let res = await this.post(path, params);
				let report = res.rows[0];
				
				if(!report) return this.$toast.add({ severity: 'info', summary: 'Report Not Found', detail: 'Please check report id', life: 3000 });

				this.doctor = report.assign_doc_name;
				this.since = this.fromNow(report.created_at);
				this.status = report.status;


				if(fd.support_reason == 'Review Request') fd.review_notes = report.review_notes ? report.review_notes : 'null';
			}
			


			await this.post('https://support.snafindia.in/api/tickets/add', fd)

			// this.support = {};
			this.ticketRaised = true;
			this.ticketRaising = false;

			// this.addModal.hide();
			// return this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Your request has been sent.', life: 3000 });
		}
		
	},
}
</script>
<style scoped>
@media print {
	.sidebar,
	header {
		display: none;
	}
}

.top-container {
	min-height: 100vh;
	/* background: rgb(235 237 239); */
	background: rgb(247 247 247);
	padding-left: 56px;
}

header {
	background: #fff;
	box-shadow: 0px 4px 8px #ccc;
	border-bottom: 1px solid #ccc;
	height: 56px;
}

.rnav {
	float: right;
	padding-right: 10px;
}

.rnav>div {
	color: #555;
	padding: 15px;
	cursor: pointer;
	font-size: 18px;
	position: relative;
	float: right;
}

.rnav>div:hover {
	color: #333;
}

.rnav>div a {
	color: #555;
}

.rnav .notifications {
	position: absolute;
	width: 372px;
	height: 400px;
	right: 0;
	background: #fff;
	z-index: 5;
	border: 1px solid #ccc;
	margin-top: 13px;
	box-shadow: 1px 0px 2px #cccc;
	overflow-y: auto;
	outline: none;
}

.rnav .notifications .noti {
	font-size: 16px;
	padding: 5px 10px;
	border-bottom: 1px solid #ccc;
}

.rnav .notifications .noti .date {
	font-size: 14px;
	color: #9f6a6a;
	float: right;
}

.tnav {
	float: left;
}

.tnav ul,
.tnav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.tnav ul {
	padding-left: 15px;
}

.tnav li {
	float: left;
	padding: 15px;
}

.tnav li a {
	text-decoration: none;
	padding: 5px;
	font-size: 14px;
	color: rgba(0, 0, 21, .5);
	cursor: pointer;
}

.tnav li a:hover,
.tnav li a.router-link-exact-active {
	color: rgba(0, 0, 21, .7);
}

/* width */
.sidebar ::-webkit-scrollbar {
	width: 8px;
	border-radius: 20px;
}

/* button */
.sidebar ::-webkit-scrollbar-button {
	background: #222;
	height: 0;
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
}

/* The track NOT covered by the handle.*/
.sidebar ::-webkit-scrollbar-track-piece {
	background: rgba(0, 0, 0, 0);
}

/* Corner */
.sidebar ::-webkit-scrollbar-corner {
	background: rgba(0, 0, 0, 0);
}

/* Resizer */
.sidebar ::-webkit-resizer {
	background: rgba(0, 0, 0, 0);
}

.sidebar {
	width: 56px;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5;
	overflow: hidden;
	/* background: rgb(60 75 100); */
	background: rgb(236 237 240);
	transition: width .3s;
	font-size: .875rem;
	border-right: 1px solid #ddd;
	box-shadow: 1px 0 14px #ccc;
}

.sidebar:hover {
	width: 256px;
}

.snav-container {
	height: 100%;
	padding-bottom: 56px;
}

.sidebar .snav {
	overflow-y: hidden;
}

.sidebar:hover .snav {
	height: 100%;
	overflow-y: overlay;
}

.sidebar .snav ul,
.sidebar .snav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.sidebar .snav li {
	overflow: hidden;
	font-size: .875rem;
	font-weight: 400;
}


.sidebar .snav li a {
	overflow: hidden;
	/* color: rgba(255,255,255,.8); */
	color: #555;
	height: 56px;
	min-width: 256px;
	padding: 18px;
	padding-right: 10px;
	transition: background .3s, color .3s;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.sidebar .snav li a:hover,
.sidebar .snav li a.router-link-exact-active {
	color: #fff;
	/* background: #321fdb; */
	background: rgb(239 151 26);
}

.sidebar .snav li a * {
	visibility: hidden;
}

.sidebar .snav li a .fa {
	font-size: 17px;
	margin-right: 15px;
	visibility: visible;
}

.sidebar .snav li a .fa-chevron-left {
	font-size: 12px;
	margin-top: 5px;
	transition: transform .3s;
}

.sidebar:hover .snav li a * {
	visibility: visible;
}

.sidebar .snav>ul>li>ul {
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s;
}

.sidebar:hover .snav>ul>li.open>ul {
	max-height: 900px;
}

.sidebar:hover .snav>ul>li.open>a .fa-chevron-left {
	transform: rotate(-90deg);
}

.sidebar .snav>ul>li>ul a {
	padding-left: 30px;
	/* background: rgb(81 98 126); */
	background: rgb(206 206 206);
}


.sidebar .title {
	text-transform: uppercase;
	color: rgba(255, 255, 255, .6);
	color: #000;
	height: 0px;
	overflow: hidden;
	transition: .3s;
}

.sidebar:hover .title {
	height: auto;
	padding: .75rem 1rem;
	margin-top: 1rem;
	font-size: 80%;
	font-weight: 700;
}

.logo {
	/* background: rgb(236 237 240); */
	background: rgb(206 206 206);
	height: 56px;
	position: relative;
	padding: 8px;
}

.logo img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
}

.logo .small {
	display: block;
}

.logo .full {
	display: none;
}

.sidebar:hover .logo .full {
	display: block;
}

.sidebar:hover .logo .small {
	display: none;
}
</style>