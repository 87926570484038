<template>
	<div style="padding: 10px;">
		<table class="table table-striped">
			<thead>
				<tr>
					<th>ID</th>
					<th>Support Reason</th>
					<th>Comment</th>
					<th>Status</th>
					<th>Time</th>
					<th>Option</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row of rows" :key="row">
					<td>{{ row.ticket_id }}</td>
					<td>{{ row.support_reason }}</td>
					<td>{{ row.reason_description }}</td>
					<td>{{ row.ticket_status }}</td>
					<td>{{ format(row.ticket_raised_on, "Do MMMM yyyy hh:mm a") }}</td>
					<td>
						<button v-if="row?.replies?.reply_content" type="button" class="btn btn-primary" @click="replyContent = row?.replies?.reply_content; addModal.show()">
							<i class="fa-solid fa-sticky-note"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>


		<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title clearfix" style="width: 100%;">
							Reply Notes
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						{{ replyContent }}
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import { Modal } from 'bootstrap'
export default {
	data(){
		return {
			rows: [],
			addModal: null,
			replyContent: '',
		}
	},
	async mounted(){
		this.addModal = new Modal(this.$refs.addModal, { backdrop: 'static', keyboard: false });
	},
	async created(){
		let center = JSON.parse(localStorage.center_access_token);

		let res = await this.post("https://support.snafindia.in/api/tickets", {
			
			"filter": {
				"condition":[
					["center_id", "=", center.id],
					["module", "=", 'TR'],
				]
			},
			"start_row":0,
			"page_records": 50,
			"sort_field": "ticket_id",
			"sort": -1
		})
		this.rows = res.rows;
		console.log(res);
	}
}
</script>
